[data-reach-combobox-popover] {
  font-size: 100%;
  border-radius: 5px;
  border: 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.45), 0 20px 40px 0 rgba(0, 0, 0, 0.25);
}

.SelectPerson_item {
  padding: 0;
  color: inherit;
  display: block;
  width: 100%;
  text-align: left;
  font-weight: 400;
  border-radius: 4px;
}

.SelectPerson_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

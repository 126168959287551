:root {
  --reach-menu-button: 1;

  --color-umbra: rgba(0, 0, 0, 0.1); /* a.k.a drop shadow */
  --color-penumbra: rgba(0, 0, 0, 0.05); /* a.k.a ambient shadow */
  /* --shadow-highlight: inset 0 0.5px 2px rgba(255, 255, 255, 0.5); provide contrast in dark mode */
  --shadow-highlight: 0 0 0 0 rgba(0, 0, 0, 0);
  --shadow-highlight-inset: inset 0 -0.5px 2px rgba(255, 255, 255, 0.5); /* provide contrast in dark mode */

  --shadow-none: 0 0 0 var(--color-umbra), 0 0 0 0 var(--color-penumbra);
  --shadow-1: 0 1px 2px var(--color-umbra), 0 1px 3px 1px var(--color-penumbra),
    var(--shadow-highlight);
  --shadow-2: 0 1px 2px var(--color-umbra), 0 2px 6px 2px var(--color-penumbra),
    var(--shadow-highlight);
  --shadow-3: 0 1px 3px var(--color-umbra), 0 4px 8px 3px var(--color-penumbra),
    var(--shadow-highlight);
  --shadow-4: 0 2px 3px var(--color-umbra), 0 6px 10px 4px var(--color-penumbra),
    var(--shadow-highlight);
  --shadow-5: 0 4px 4px var(--color-umbra), 0 8px 12px 6px var(--color-penumbra),
    var(--shadow-highlight);
}

:root,
:root[data-theme="tuia"] {
  --backgroundColor: #f0f0f0;
  --backgroundHalfColor: #f7f7f7;
  --cardColor: #ffffff;
  --shadowColor: 0deg 0% 63%;
  --textColor: #000000;
  --subtleColor: #8c8c8c;
  /* --featureColor: #00c0c0; */
  --featureColor: hsl(190, 100%, 40%);
  --inverseColor: #9500c2;
  --positiveColor: #0099ff;
  --negativeColor: #ff524c;
  --selectionColor: hsl(210, 100%, 85%);
  /* --selectionColor: hsl(190, 75%, 83%); */
  /* --selectionColor: #80ccff; */
  --switchColor-false: #a6a6a6;
  --switchColor-true: #0099ff;

  --reach-menu-button: 1;
}
:root[data-theme="goodnews"] {
  --backgroundColor: #e8f3ef;
  --backgroundHalfColor: #f3f9f7;
  --cardColor: #ffffff;
  --shadowColor: 159deg 11% 58%;
  --textColor: #000000;
  --subtleColor: #6aaf95;
  --featureColor: #0eaa71;
  --inverseColor: #ff614c;
  --positiveColor: #11d48d;
  --negativeColor: #ff614c;
  --selectionColor: #7ee8c1;
  --switchColor-false: #7ee8c1;
  --switchColor-true: #22c380;
}
:root[data-theme="discipler"] {
  --backgroundColor: #f5ebe0;
  --backgroundHalfColor: #faf5ef;
  --cardColor: #fffaf5;
  --shadowColor: 32deg 19% 61%;
  --textColor: #000000;
  --subtleColor: #9d8c7b;
  --featureColor: #74593e;
  --inverseColor: #e66000;
  --positiveColor: #b30077;
  --negativeColor: #ff0000;
  --selectionColor: #dcc4ad;
  --switchColor-false: #d1b294;
  --switchColor-true: #b37f4c;
}
:root[data-theme="serve"] {
  --backgroundColor: #f3efe8;
  --backgroundHalfColor: #f9f7f3;
  --cardColor: #fcfcfc;
  --shadowColor: 39deg 11% 63%;
  --textColor: #000000;
  --subtleColor: #8c8473;
  --featureColor: #b88114;
  --inverseColor: #1f8193;
  --positiveColor: #f09f00;
  --negativeColor: #ff524c;
  --selectionColor: #e9d2a5;
  --switchColor-false: #e9d2a5;
  --switchColor-true: #b88114;
}
:root[data-theme="unjust"] {
  --backgroundColor: #dbe8eb;
  --backgroundHalfColor: #edf3f5;
  --cardColor: #ffffff;
  --shadowColor: 192deg 12% 60%;
  --textColor: #000000;
  --subtleColor: #7b909e;
  --featureColor: #0084a8;
  --inverseColor: #ed4d33;
  --positiveColor: #00afe0;
  --negativeColor: #ed4d33;
  --selectionColor: #99d5e6;
  --switchColor-false: #8dcfe2;
  --switchColor-true: #00afe0;
}
:root[data-theme="creation"] {
  --backgroundColor: #eff2e8;
  --backgroundHalfColor: #f7f8f3;
  --cardColor: #fcfcfc;
  --shadowColor: 78deg 10% 62%;
  --textColor: #000000;
  --subtleColor: #838a75;
  --featureColor: #627e2a;
  --inverseColor: #983398;
  --positiveColor: #77b300;
  --negativeColor: #ff5533;
  --selectionColor: #c7d9a6;
  --switchColor-false: #c7d9a6;
  --switchColor-true: #86ac39;
}
:root[data-theme="wayfinder"] {
  --backgroundColor: #242424;
  --backgroundHalfColor: #2f2f2f;
  --cardColor: #363230;
  --shadowColor: 0deg 0% 6%;
  --textColor: #fafafa;
  --subtleColor: #b3b3b3;
  --featureColor: #fefefe;
  --inverseColor: #b380ff;
  --positiveColor: #ff9e75;
  --negativeColor: #ff6666;
  --selectionColor: #cc8466;
  --switchColor-false: #cc8466;
  --switchColor-true: #ff9e75;
}
:root {
  --pageColor: var(--cardColor);
  --regularColor: var(--textColor);
  --highlightColor: var(--positiveColor);
  --tintColor: var(--subtleColor);
  --red: var(--negativeColor);
}

.text-subtleColor {
  color: var(--subtleColor);
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--backgroundHalfColor);
  color: var(--regularColor);
  line-height: 1.6;
}

img {
  max-width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--featureColor);
  font-weight: 400;
}

small {
  color: var(--subtleColor);
}

pre,
code,
kbd {
  font-family: "Roboto Mono", -ui-monospace, monospace;
}

summary {
  user-select: none;
  cursor: pointer;
}

button,
.button {
  display: inline-flex;
  align-items: center;
  text-align: center;
  font: inherit;
  height: 40px;
  line-height: 1;
  border-radius: 9999px;
  border: 0px solid transparent;
  background-color: transparent;
  color: var(--highlightColor);
  padding: 0 20px;
  line-height: 1.25;
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  position: relative;
}

button::before,
.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: inherit;
  background-color: currentColor;
  opacity: 0;
}
button:hover::before,
.button:hover::before {
  opacity: 0.12;
}
button:focus::before,
.button:focus::before {
  opacity: 0.15;
}

/* button:hover,
.button:hover {
  background-color: rgba(0, 0, 0, 0.05);
} */

button[disabled] {
  opacity: 0.5;
  cursor: default;
}

.button.destructive {
  color: var(--red);
}

.button-reset {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.button-reset.button-reset::before {
  opacity: 0;
}

button.outlined,
.button.outlined {
  border: 1px solid var(--highlightColor);
  padding: 0 19px;
  border-color: var(--highlightColor);
}

button.solid,
.button.solid {
  border-color: var(--highlightColor);
  background-color: var(--highlightColor);
  color: var(--pageColor);
}

input,
textarea {
  font: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 600;
}

/* *:focus:not([disabled]):not([tabindex="-1"]) {
  outline: 2px solid var(--highlightColor);
} */
*:focus:not(:focus-visible) {
  outline: none !important;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 8px 0;
}

dd {
  margin: 0;
}

.table {
  border-collapse: collapse;
}

.table tr:nth-child(2n) {
  background-color: rgba(128, 128, 128, 0.1);
}

.table td {
  padding: 4px 12px;
}

/* FAKE COMPONENTS */

/* .text-field {
  display: block;
  width: 100%;
  font-size: 18px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  padding-inline: 8px;
  border-radius: 4px;
} */

.inline-icon {
  fill: currentColor;
  vertical-align: text-bottom;
}

.text-field {
  display: block;
  width: 100%;
  font-size: 18px;
  margin-bottom: 8px;
  border: none;
  border-bottom: 1px solid var(--subtleColor);
  background-color: transparent;
  color: inherit;
  font: inherit;
  /* padding-inline: 8px; */
  /* border-radius: 4px; */
}

.textarea {
  width: 100%;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 12px 12px 4px 12px;
}

.error-message {
  color: var(--red);
  margin: 0;
  text-align: center;
}

.card {
  display: block;
  /* border: 1px solid var(--subtleColor); */
  background-color: var(--cardColor);
  box-shadow: var(--shadow-3);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
}

.AdminPage_grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.AdminPage_grid-item {
  border: 1px solid var(--subtleColor);
  border-radius: 12px;
  padding: 16px;
  margin: 0 8px 16px;
  flex: 1 0 300px;
}

.SiteHeader {
  width: 100%;
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); */
  /* backdrop-filter: blur(20px); */
  position: sticky;
  top: 0;
  height: 56px;
  min-height: 56px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 16px;
}

/* .SiteHeader + * {
  padding-top: 16px;
} */

/* UTILITY CLASSES */

.width-wrapper {
  max-width: 900px;
  padding-inline: 8px;
  margin-inline: auto;
}

.page-wrapper {
  max-width: 700px;
  /* padding-inline: 16px; */
  margin-inline: auto;
  margin-bottom: 256px;
}

.width-wrapper-lg {
  max-width: 2000px;
  padding-inline: 16px;
  margin-inline: auto;
  margin-bottom: 256px;
}

.d-block {
  display: block;
}
.w-100 {
  width: 100%;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.p-relative {
  position: relative;
}

.flex-row {
  display: flex;
}

.danger {
  color: var(--red);
  fill: var(--red);
}
.highlight {
  color: var(--highlightColor);
  fill: var(--highlightColor);
}

button.outlined.danger {
  color: var(--red);
  border-color: var(--red);
}

button.solid.danger {
  color: var(--pageColor);
  background-color: var(--red);
  border-color: var(--red);
}

[data-reach-combobox-popover] {
  font-size: 100% !important;
  border-radius: 5px;
  border: 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.45), 0 20px 40px 0 rgba(0, 0, 0, 0.25);
  padding: 4px;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.col {
  flex: 1 0 400px;
  overflow: auto;
  padding: 0 8px 16px;
}

@tailwind components;
@tailwind utilities;

.PrayerList {
  overflow-wrap: break-word;
}

.PrayerList * {
  overflow-wrap: inherit;
  word-break: break-word;
}

.PrayerList p {
  margin: 0.8rem 0;
}

.PrayerList h3 {
  margin-bottom: 0;
}

.PrayerList h3 + p,
.PrayerList h3 + ul {
  margin-top: 0.4rem;
}

.PrayerList li {
  margin-bottom: 0.2rem;
}

.PrayerList a {
  color: var(--positiveColor);
}

.PrayerList a:hover {
  text-decoration: underline;
}

.ProfilePicDropzone {
  display: block;
  border-radius: 50%;
  width: 128px;
  position: relative;
}
.ProfilePicDropzone.hovered::after {
  pointer-events: none;
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  /* box-sizing: content-box; */
  border: 4px dashed var(--highlightColor);
}
.ProfilePicLoading {
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.67);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ChangePicSection {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 48px;
  height: 48px;
}

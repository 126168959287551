.calendar-outline-visible {
  margin-right: 150px;
}

.CalendarOutline {
  position: fixed;
  top: 0;
  right: 0;
  /* width: 200px; */
  padding: 16px 0;
  height: 100vh;
  font-family: "Roboto Mono", ui-monospace, Courier, monospace;
  color: var(--featureColor);
  display: flex;
  flex-direction: column;
}

.CalendarOutline_title {
  padding: 0 12px;
  font-weight: 600;
}

.CalendarOutline_ul {
  padding: 16px 12px;
  flex: 1;
  padding-left: 0;
  overflow-y: auto;
  list-style: none;
  margin: 0;
}

.CalendarOutline_li {
  display: block;
  font-weight: 400;
  padding: 0 12px;
  border-radius: 99px;
  /* color: var(--featureColor); */
}

.CalendarOutline_li.active {
  background-color: var(--selectionColor);
  color: var(--regularColor);
  /* color: var(--cardColor); */
}

.CalendarOutline_li:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.CalendarOutline_li.active:hover {
  background-color: var(--selectionColor);
  color: var(--regularColor);
}

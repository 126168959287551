.BulkEditor_grid {
  display: flex;
  margin: 0 -16px;
  min-height: 300px;
  max-height: calc(100vh - 200px);
}

.BulkEditor_col {
  flex: 1 0 1px;
  margin: 0 16px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.BulkEditor_preview {
  flex: 1;
  overflow-y: scroll;
}

.BulkEditor_input {
  border: 2px solid var(--subtleColor);
  border-radius: 4px;
  padding: 4px;
  height: 100%;
  width: 100%;
  resize: none;
  outline-offset: -2px;
}

.BulkEditor_arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  color: var(--highlightColor);
  user-select: none;
}

.BulkEditor_savebutton {
  position: fixed;
  bottom: 0;
  right: 0;
}

.DialogOverlay {
  /* background: hsla(0, 0%, 50%, 0.33); */
  background: hsl(var(--shadowColor) / 0.33);
}

.DialogContent {
  /* border: 1px solid var(--backgroundColor); */
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 20px 40px 0 hsl(var(--shadowColor) / 0.5);
  width: 100%;
  max-width: 700px;
  background-color: var(--cardColor);
  position: relative;
}

.Dialog_close {
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  color: var(--subtleColor);
}

.minimal.DialogOverlay {
  background: hsla(0, 0%, 50%, 0.15);
}

.minimal.DialogContent {
  margin-top: 12vh;
  border: 0px solid var(--tintColor);
  border-radius: 4px;
  box-shadow: 0px 0px 3px hsl(var(--shadowColor) / 0.45),
    0 5px 10px hsl(var(--shadowColor) / 0.15);
  width: 100%;
  max-width: 350px;
  padding: 4px 8px;
}

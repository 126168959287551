body.drawer-visible {
  padding-left: 300px;
}

.Drawer {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  width: 300px;
  padding: 16px 12px;
  border-right: none;
}
.Drawer_label {
  font-size: 1rem;
  margin: 0;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-weight: 700;
  color: var(--subtleColor);
}
.Drawer_button {
  display: flex;
  width: 100%;
  text-decoration: none;
  align-items: center;
  line-height: 1;
  text-align: center;
  line-height: 1.2;
  position: relative;
  border-radius: 28px;
  height: 56px;
  padding: 0 16px;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 500;
}
.Drawer_button > svg {
  margin-right: 12px;
}
.Drawer_button.active {
  outline: 0 !important;
  font-weight: 600;
  background-color: var(--selectionColor);
}
.Drawer_button:not(.active):hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.Drawer_dot {
  position: absolute;
  background-color: var(--highlightColor);
  border-radius: 50%;
  border: none;
  width: 8px;
  height: 8px;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
}
.Drawer_button:hover > .Drawer_dot,
.Drawer_button.active > .Drawer_dot {
  border-color: #f5f5f5;
}

body.rail-visible {
  padding-left: 72px;
}

.Rail {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 72px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--backgroundColor);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.Rail_label {
  font-size: 0.875rem;
  margin: 0;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-weight: 500;
  display: none;
}

.Rail_button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64px;
  text-decoration: none;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  font-size: 10px;
  line-height: 1.2;
  position: relative;
  padding: 0;
  letter-spacing: 0.02em;
}
.Rail_button.active {
  outline: 0 !important;
  font-weight: 600;
}
.Rail_highlight {
  width: 56px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  position: relative;
}

.Rail_button.active > .Rail_highlight {
  background-color: var(--selectionColor);
}

.Rail_button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.Rail_dot {
  position: absolute;
  background-color: var(--highlightColor);
  border: 2px solid #ededed;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 0px;
  right: 6px;
}
.Rail_button.active .Rail_dot {
  border-color: var(--selectionColor);
}

body.button-visible {
  padding-top: 48px;
}
.MenuButton {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 50%;
  border: 1px solid var(--subtleColor);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.33);
  z-index: 2;
  background-color: var(--cardColor);
  color: var(--subtleColor);
}

.ModalDrawer_overlay.ModalDrawer_overlay {
  z-index: 2;
  background: hsl(var(--shadowColor) / 0.67);
  display: flex;
  justify-content: flex-start;
}
.ModalDrawer_overlay.entering {
  animation: modal-drawer-overlay-enter 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.ModalDrawer_overlay.exiting {
  animation: modal-drawer-overlay-exit 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes modal-drawer-overlay-enter {
  from {
    background: hsl(var(--shadowColor) / 0);
  }
  to {
    background: hsl(var(--shadowColor) / 0.67);
  }
}
@keyframes modal-drawer-overlay-exit {
  to {
    background: hsl(var(--shadowColor) / 0);
  }
  from {
    background: hsl(var(--shadowColor) / 0.67);
  }
}

.ModalDrawer.ModalDrawer {
  width: 300px;
  margin: 0;
  background-color: var(--backgroundColor);
  padding: 16px 12px;
  border-radius: 0 16px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}
.ModalDrawer_overlay.entering > .ModalDrawer {
  animation: modal-drawer-content-enter 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.ModalDrawer_overlay.exiting > .ModalDrawer {
  animation: modal-drawer-content-exit 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes modal-drawer-content-enter {
  from {
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes modal-drawer-content-exit {
  to {
    transform: translateX(-300px);
  }
  from {
    transform: translateX(0px);
  }
}

.ModalDrawer_button {
  width: 56px;
  height: 56px;
  padding: 0;
  margin: 0;
  color: var(--regularColor);
  justify-content: center;
}

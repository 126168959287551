.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 900px;
  margin: auto;
  color: black;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  height: 64px;
  width: 100%;
}

.header nav {
  overflow-x: auto;
  height: 64px;
  display: flex;
  align-items: center;
}
.header ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
}
.header li {
  flex-shrink: 0;
}
.header nav a {
  padding: 0 16px;
  font-weight: 600;
  height: 40px;
  display: flex;
  align-items: center;
}

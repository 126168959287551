.BaseMenuList {
  background: var(--pageColor);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5), 0 5px 10px rgba(0, 0, 0, 0.15);
  padding: 4px;
  border-radius: 4px;
  outline: 0 !important;
  width: max-content;
}

.BaseMenuItem {
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.BaseMenuItem:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.ProfilePicture {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ProfilePicture_text {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  line-height: 1;
  font-size: 32px;
  display: flex;
  text-decoration: none;
  user-select: none;
}

.ProfilePicture.xs {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
.ProfilePicture.xs > .ProfilePicture_text {
  font-size: 12px;
}

.ProfilePicture.sm {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}
.ProfilePicture.sm > .ProfilePicture_text {
  font-size: 16px;
}

.ProfilePicture.md {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
}
.ProfilePicture.md > .ProfilePicture_text {
  font-size: 24px;
}
.ProfilePicture.xl {
  width: 128px;
  height: 128px;
  min-width: 128px;
  min-height: 128px;
}
.ProfilePicture.xl > .ProfilePicture_text {
  font-size: 64px;
}

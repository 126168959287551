.EditorPage_wrapper {
  padding: 0 4rem;
  max-width: 800px;
}

.EditorPage_card {
  position: relative;
}
.EditorPage_side-buttons {
  position: absolute;
  top: 0;
  width: 4rem;
  height: 100%;
  padding: 1rem;
  opacity: 0.25;
}

@keyframes bounce-reminder {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(-4px);
  }
  8% {
    transform: translateY(1px);
  }
  12% {
    transform: translateY(0);
  }
}
.EditorPage_save-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  font-size: 125%;
  padding: 16px 32px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  animation: bounce-reminder 5s ease-in-out 0s infinite normal none running;
}

.DeleteButton_popover {
  background-color: var(--pageColor);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.45), 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1rem;
}

.LinkButton_icon {
  fill: var(--highlightColor);
  opacity: 0.5;
}

.LinkButton_icon.active {
  opacity: 1;
}

.EditorBlock:hover .LinkButton_icon {
  opacity: 1;
}

.LinkButton_label {
  padding-right: 4px;
}

.LinkButton_form {
  display: inline;
}

.EditorPage_hover-button {
  opacity: 0.5;
}

.EditorPage_card:hover > .EditorPage_side-buttons,
.EditorPage_card:hover .EditorPage_hover-button,
.EditorPage_side-buttons:hover {
  opacity: 1;
}
.EditorPage_side-buttons.left {
  left: -4rem;
}
.EditorPage_side-buttons.right {
  right: -4rem;
}

.EditorPage_arrow-fix {
  transform: rotate(90deg);
}

.EditorBlock_item {
  flex-grow: 1;
  padding: 0;
}

.EditorPage_divider {
  border-top: 1px solid var(--backgroundColor);
  margin: 8px 0;
}

.EditorBlock_paragraph {
  padding-bottom: 0.8rem;
}

.EditorBlock_heading {
  font-weight: 700;
  font-size: 18px;
  padding-top: 0.4rem;
}

.EditorBlock_list_item {
  padding-bottom: 0.2rem;
}

.EditorBlock_note {
  font-size: small;
}

.EditorBlock_bullet {
  margin: 8px;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor;
}

.EditorBlock_link-button {
  max-width: 24px;
  min-width: 24px;
  height: 32px;
  padding-bottom: 8px;
  margin: auto 0;
}

.EditorBlock_has-link {
  color: var(--positiveColor);
}

.ErrorMessage {
  text-align: center;
  max-width: 440px;
}

.ErrorMessage_kaomoji {
  color: var(--subtleColor);
  font-size: 3rem;
  user-select: none;
  cursor: default;
  transform: translateY(0);
  transition: transform 150ms cubic-bezier(0.5, 0, 1, 0.5);
  margin-bottom: 0;
}
.ErrorMessage_kaomoji:active {
  transform: translateY(-4px);
  transition: transform 150ms cubic-bezier(0, 0.5, 0.5, 1);
}

.ErrorMessage_title {
  margin-top: 0;
}

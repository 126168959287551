.GrowInput {
  display: grid;
}
.GrowInput::after {
  content: attr(data-dupe) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.GrowInput > textarea {
  resize: none;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.GrowInput > textarea,
.GrowInput::after {
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  grid-area: 1 / 1 / 2 / 2;
  margin: 0;
}

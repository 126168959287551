.PrayerCalendar_section {
  padding: 16px;
}
.PrayerCalendar_section + .PrayerCalendar_section {
  border-top: 1px solid var(--backgroundColor);
}

.PrayerCalendar_date {
  color: var(--textColor);
}
